/* eslint-disable camelcase */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import ThemisServices from 'services/ThemisServices'

export type TrainMessageNoId = {
  type_message: 'train'
  numero_marche: string
  retard: number
  localisation: string
  heure_obs: string
  plain_text: boolean
}
export type TrainMessage = TrainInfoMessageNoId & {id: string}

export type ChronoMessageNoId = {
  type_message: 'annonce'
  message: string
  action: string
  chrono?: number
  chrono_initial?: number
  heure_obs: string
  message_avertissement?: string
}
export type ChronoMessage = ChronoMessageNoId & {id: string}

export type TrainInfoMessageNoId = TrainMessageNoId | ChronoMessageNoId
export type TrainInfoMessage = TrainMessage | ChronoMessage
export type StatusMessage = {
  type_message: 'status'
  observations: boolean
  'circulations-theoriques': boolean
}

type WebSocketMessage = TrainInfoMessageNoId | StatusMessage

export type WSEvent = {
  type_message: 'events'
  timestamp: string
  messages: WebSocketMessage[]
}

export type ActiveCountdown = {
  messageId: string
  duration: number
  timeLeft: number
}

export type CAState = {
  messages: TrainInfoMessage[]
  activeCountdown: ActiveCountdown | null
  clockSkew: number
  activeAudio: boolean
  status: {
    observations: boolean
    theoricalTrains: boolean
  }
}

const initialState: CAState = {
  messages: [],
  activeCountdown: null,
  clockSkew: 0,
  activeAudio: false,
  status: {
    observations: false,
    theoricalTrains: false,
  },
}

export const caSlice = createSlice({
  name: 'ca',
  initialState,
  reducers: {
    updateMessages: (state, action: PayloadAction<TrainInfoMessage[]>) => {
      state.messages = action.payload
      const newChrono = action.payload.find(m => m.type_message === 'annonce' && m.chrono) as ChronoMessage | undefined

      if (newChrono?.chrono && newChrono?.chrono_initial && newChrono.chrono > 0) {
        state.activeCountdown = {
          messageId: newChrono.id,
          duration: newChrono.chrono_initial,
          timeLeft: newChrono.chrono,
        }
      } else {
        state.activeCountdown = null
      }
    },
    deactivateCountdown: (state, action: PayloadAction<string>) => {
      if (state.activeCountdown?.messageId === action.payload) {
        state.activeCountdown = null
      }
    },
    resetMessages: state => {
      state.messages = []
      state.activeCountdown = null
    },
    toggleActiveAudio: (state, action: PayloadAction<boolean>) => {
      state.activeAudio = action.payload
    },
    setStatus: (state, action: PayloadAction<{observations: boolean, theoricalTrains: boolean}>) => {
      state.status = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(ThemisServices.getTime.fulfilled, (state, { payload }) => {
      const nowTimeStamp = (new Date()).getTime()

      const serverTimestamp = payload[0].server_time * 1000.0
      const clientTimestamp = payload[1]

      const clockSkew = ((serverTimestamp - clientTimestamp) + (serverTimestamp - nowTimeStamp)) / 2.0

      state.clockSkew = clockSkew
    })
  },
})

export const {
  deactivateCountdown, updateMessages, resetMessages, toggleActiveAudio, setStatus,
} = caSlice.actions

export default caSlice.reducer
