import { RootState } from 'Store'
import { isDevEnv } from 'components/utils'
import { GATEWAY_DOMAIN } from 'config/config'
import {
  MutableRefObject, ReactElement, useEffect, useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PPNStreamState, updateFluxState } from 'reducers/ppn'
import moment from 'moment'
import {
  DOWN_FLUX_EVENT, Event, debouncedRestart, handleEvent,
} from './utils'

export default function FIFOStream(): ReactElement {
  const dispatch = useDispatch()
  const { shouldRestart } = useSelector((state: RootState) => state.ppn) as PPNStreamState
  const [statusTimestamp, setStatusTimestamp] = useState<Date>(new Date())

  const ws: MutableRefObject<WebSocket> = useRef()
  const wsUrl = isDevEnv() ? 'prio_paris_nord_mock' : 'prio_paris_nord'

  useEffect(() => {
    ws.current = new WebSocket(
      `wss://${GATEWAY_DOMAIN.url}/prio-paris-nord/${wsUrl}/?_auth_token=${`${localStorage.getItem(
        'access_token',
      )}`}`,
    )
    ws.current.onmessage = (event: MessageEvent) => {
      // checkIsDown()
      setStatusTimestamp(new Date())
      const e: Event = JSON.parse(event.data)
      handleEvent(e)
    }
    ws.current.onclose = () => {
      dispatch(updateFluxState(DOWN_FLUX_EVENT))
      debouncedRestart(!shouldRestart)
    }
    const wsCurrent = ws.current
    return () => {
      wsCurrent.close()
    }
  }, [shouldRestart])

  useEffect(() => {
    const interval = setInterval(() => {
      if (moment.duration(moment(new Date()).diff(moment(statusTimestamp))).asSeconds() > 120) {
        dispatch(updateFluxState(DOWN_FLUX_EVENT))
      }
    }, 10000)

    return () => {
      clearInterval(interval)
    }
  }, [statusTimestamp])

  return (
    <> </>
  )
}
