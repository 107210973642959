import { debounce } from '@mui/material'
import { store } from 'Store'
import {
  updateCirculations,
  updateFluxState,
  updatePriority,
  updateRestart,
} from 'reducers/ppn'
import {
  CirculationEvent, EventType, FluxStateEvent, PriorityEvent,
} from 'types'

// eslint-disable-next-line camelcase
export type Event ={ type_message: 'events',
messages: (CirculationEvent | FluxStateEvent | PriorityEvent)[]}

export const DOWN_FLUX_EVENT: FluxStateEvent = {
  type: EventType.stream_state,
  state: false,
}

// export const checkIsDown = debounce(() => store.dispatch(updateFluxState(DOWN_FLUX_EVENT)), 120000)
export const debouncedRestart = debounce((shouldRestart: boolean) => {
  store.dispatch(updateRestart(shouldRestart))
}, 1000)

export const handleEvent = (e: Event): void => {
  if (e.type_message === 'events') {
    e.messages.forEach(event => {
      switch (event.type) {
        case EventType.priority:
          store.dispatch(updatePriority(event as PriorityEvent))
          break
        case EventType.circulations:
          store.dispatch(updateCirculations(event as CirculationEvent))
          break
        case EventType.stream_state:
          store.dispatch(updateFluxState(event as FluxStateEvent))
          break
        default:
          break
      }
    })
  }
}
